<template>
  <base-popup
    :title="
      popupType === 'addUnwishedLink'
        ? 'Выбор похожих нежелательных событий для объединения'
        : 'Выбор нежелательных событий для объединения'
    "
    :icon="UtilGetIcon('view-UnwishedEvents')"
    @save="save"
    :value="dialog"
    @input="$emit('update:dialog', $event)"
    buttonSaveText="Добавить"
  >
    <unwished-event-list
      v-if="isRender"
      readonly
      v-model="selected"
      :currentUnwished="currentUnwished"
      :type="popupType"
      :sourceItems="sourceItems"
    ></unwished-event-list>
  </base-popup>
</template>
<script>
export default {
  components: {
    BasePopup: () => import("@/layouts/BasePopup.vue"),
    UnwishedEventList: () => import("./UnwishedEventList.vue"),
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    currentUnwished: {
      type: Object,
      default: null,
    },
    popupType: {
      type: String,
      default: null,
    },
    sourceItems: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selected: [],
      isRender: true,
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        setTimeout(() => {
          this.isRender = false;
        }, 500);
      } else this.isRender = true;
    },
  },
  methods: {
    save(save) {
      this.$emit("save", this.selected);
    },
  },
};
</script>
